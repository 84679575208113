import { useState } from "react";
import useSound from "use-sound";
import pop from "./sounds/Pop-sound-effect.mp3";
import mixkit from "./sounds/mixkit-retro-game-notification-212.wav";
import "./App.css";
import { addGuest } from "./utils/firebase";

function App() {
  const [open, setIsOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [firstPopped, setFirstPopped] = useState(false);
  const [secondPopped, setSecondPopped] = useState(false);
  const [thirdPopped, setThirdPopped] = useState(false);
  const [fourthPopped, setFourthPopped] = useState(false);
  const [fifthPopped, setFifthPopped] = useState(false);

  const [playPop] = useSound(
    firstPopped + secondPopped + thirdPopped + fourthPopped + fifthPopped < 4
      ? pop
      : mixkit
  );

  const isEmail = email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  function handleSubmit(e) {
    e.preventDefault();
    setError("");
    setSubmitted(false);
    if (!name || !email) {
      setError("Please enter your name and email");
      return;
    }
    if (!isEmail(email)) {
      setError("Please enter a valid email");
      return;
    }
    addGuest(name, email).then(() => {
      setSubmitted(true);
      setIsOpen(false);
      playPop();
    });
  }

  return (
    <div className="App App-header">
      <h1>
        {!firstPopped && (
          <div
            className="balloon"
            style={{ backgroundColor: "pink" }}
            onClick={() => {
              setFirstPopped(true);
              playPop();
            }}
          ></div>
        )}
        {!secondPopped && (
          <div
            className="balloon b-2"
            style={{ backgroundColor: "#99a9f7" }}
            onClick={() => {
              setSecondPopped(true);
              playPop();
            }}
          ></div>
        )}
        {!thirdPopped && (
          <div
            className="balloon b-3"
            style={{ backgroundColor: "#9debaa" }}
            onClick={() => {
              setThirdPopped(true);
              playPop();
            }}
          ></div>
        )}
        {!fourthPopped && (
          <div
            className="balloon"
            style={{ backgroundColor: "#ebe652" }}
            onClick={() => {
              setFourthPopped(true);
              playPop();
            }}
          ></div>
        )}
        {!fifthPopped && (
          <div
            className="balloon b-2"
            style={{ backgroundColor: "#52deeb" }}
            onClick={() => {
              setFifthPopped(true);
              playPop();
            }}
          ></div>
        )}
      </h1>
      {firstPopped &&
        secondPopped &&
        thirdPopped &&
        fourthPopped &&
        fifthPopped && (
          <div
            className="block card fixed"
            style={{
              minWidth: "300px",
              maxWidth: "550px",
              padding: "30px",
              margin: "50px",
            }}
          >
            <div
              style={{
                textAlign: "right",
                fontFamily: "monospace",
                fontSize: "14px",
              }}
            >
              <div className="block wrapper fixed">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/3/35/Stamp_of_Ukraine_s834.jpg"
                  height="100px"
                  width="100px"
                  alt="cat"
                />
              </div>
              <div>from: Elena Lape </div>
            </div>
            <h3 style={{ fontFamily: "monospace" }}>
              You are invited to my birthday!🎈
            </h3>
            <h6 style={{ fontFamily: "monospace", marginBottom: "10px" }}>
              2 April 2022
              <br />
              6-10PM
              <br />@ Iron Duke
              <br />
              11 Avery Row W1K 6AN
              <br />
            </h6>
            <div
              style={{
                fontFamily: "monospace",
                fontSize: "15px",
                padding: "15px",
              }}
            >
              Venue has a bar but no kitchen (plenty of snacks will be provided though!). The manager, Marta, is desperate
              to hire a chef. If you know of any chefs looking for a job, please
              let her know.
              <div style={{ marginTop: "10px" }}>
                Hope to see you on Saturday!
              </div>
            </div>
            {!open && !submitted && (
              <>
                <div
                  style={{
                    marginTop: "10px",
                    display: "block",
                    fontSize: "30px",
                  }}
                >
                  👇
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="block accent"
                    style={{
                      padding: "7px",
                      fontSize: "1.4rem",
                    }}
                    onClick={e => {
                      e.preventDefault();
                      setIsOpen(true);
                    }}
                  >
                    RSVP
                  </button>
                </div>
              </>
            )}
            {open && (
              <form>
                <div className="block fixed">
                  <input
                    type="text"
                    placeholder="Name"
                    style={{
                      padding: "5px",
                      fontFamily: "monospace",
                      fontSize: "1.2rem",
                      border: "none",
                      width: "100%",
                    }}
                    onChange={e => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="block fixed">
                  <input
                    style={{
                      padding: "5px",
                      fontFamily: "monospace",
                      fontSize: "1.2rem",
                      border: "none",
                      width: "100%",
                    }}
                    type="text"
                    placeholder="Email"
                    onChange={e => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <button
                  class="block accent"
                  style={{
                    padding: "7px",
                    fontSize: "1.2rem",
                    marginTop: "10px",
                    fontFamily: "monospace",
                  }}
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </form>
            )}
            {error && (
              <p
                style={{
                  fontSize: "15px",
                  color: "red",
                  fontFamily: "monospace",
                  textAlign: "left",
                  paddingLeft: "2px",
                }}
              >
                {error}
              </p>
            )}
            {submitted && (
              <h6
                style={{
                  textAlign: "left",
                  fontSize: "15px",
                  fontFamily: "monospace",
                  color: "green",
                }}
              >
                Thanks! I'll send you a calendar reminder the day before.
              </h6>
            )}

            <hr />
            <div style={{ display: "flex", justifyContent: "right" }}>
              <a
                href="https://g.page/the-iron-duke-mayfair?share"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <button
                  className="block"
                  style={{
                    padding: "7px",
                    fontSize: "1rem",
                    fontFamily: "monospace",
                  }}
                >
                  Directions ↗
                </button>
              </a>
            </div>
          </div>
        )}
    </div>
  );
}

export default App;
